import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
} from "react";

import L from "leaflet";

export interface MapContextType {
  map: L.Map | null;
  setMap: (map: L.Map | null) => void;
  invalidateMapSize: () => void;
}

const defaultContext: MapContextType = {
  map: null,
  setMap: () => {},
  invalidateMapSize: () => {},
};

const MapContext = createContext<MapContextType>(defaultContext);

interface MapProviderProps {
  children: ReactNode;
}

export const MapProvider: React.FC<MapProviderProps> = ({ children }) => {
  const [map, setMap] = useState<L.Map | null>(null);

  const invalidateMapSize = useCallback(() => {
    if (map) {
      requestAnimationFrame(() => {
        map.invalidateSize({ animate: false, pan: false });
        map.fire("moveend");
      });
    }
  }, [map]);

  const value = useMemo(() => {
    return {
      map,
      setMap,
      invalidateMapSize,
    };
  }, [invalidateMapSize, map]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export const useMapContext = (): MapContextType => {
  return useContext(MapContext);
};
