import { create } from "zustand";

interface LanguageLoadingState {
  isLanguageChanging: boolean;
  setLanguageChanging: (isChanging: boolean) => void;
}

export const useLanguageLoadingStore = create<LanguageLoadingState>((set) => ({
  isLanguageChanging: false,
  setLanguageChanging: (isChanging) => set({ isLanguageChanging: isChanging }),
}));
